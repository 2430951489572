<template>
  <div id="profile">
    <defaultTemplate>
      <!-- img -->
      <div class="pd-5">
        <div class="text-right" v-if="!editdata">
          <a class="text-primary cursor-pointer" @click="editdata = true"
            ><i class="el-icon-edit fas"></i> Edit Profile</a
          >
        </div>
        <div v-else>
          <a class="text-primary cursor-pointer" @click="editdata = false"
            ><i class="el-icon-arrow-left fas"></i
          ></a>
        </div>
      </div>

      <div class="profile-info pd-x-5 pd-y-3" v-if="getProfiles">
        <div
          class="text-center box--profile"
          style="position: relative"
          :class="{ 'edit--data': editdata }"
        >
          <div
            class="mg-x-auto show-pic"
            :style="`width:${whProfile}px;height:${whProfile}px;`"
          >
            <div class="icon-upload" v-if="editdata">
              <el-upload
                class="avatar-uploader"
                :show-file-list="false"
                action="#"
                :on-change="uploadProfile"
              >
                <div class="text-white font-14">
                  <i class="fas fa-pen"></i> Edit
                </div>
              </el-upload>
            </div>
            <el-avatar
              v-if="renderProfile"
              :size="whProfile"
              :src="
                typeof getProfiles.profileImage == `undefined` ||
                getProfiles.profileImage == null
                  ? profile.profileImage
                  : getProfiles.profileImage
              "
            ></el-avatar>
          </div>
        </div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
          <div v-if="editdata">
            <el-form-item label="First name" prop="firstName">
              <el-input v-model="ruleForm.firstName"></el-input>
            </el-form-item>
            <el-form-item label="Last name" prop="lastName">
              <el-input v-model="ruleForm.lastName"></el-input>
            </el-form-item>
          </div>
          <h3 v-else class="text-center font-18 font-weight-500">
            {{ getProfiles.firstName }} {{ getProfiles.lastName }}
          </h3>
          <div class="is-flex js-between">
            <p class="font-16 color-70">E-mail</p>
            <p class="font-16 color-35 font-weight-500">
              {{ getProfiles.email }}
            </p>
          </div>
          <el-divider></el-divider>
          <div class="mg-t-5 font-14">
            <!-- <div class="is-flex js-between">
              <p class="font-16 color-70">Curriculum</p>
              <p class="font-16 color-35 font-weight-500">
                {{ profile.curriculum.join(", ") }}
              </p>
            </div>
            <el-divider></el-divider> -->
            <div>
              <div class="is-flex js-between">
                <p class="font-16 color-70">Package</p>
                <p class="font-16 color-35 font-weight-500">
                  {{ profile.package }}
                </p>
              </div>
              <el-divider></el-divider>
            </div>
            <!-- <div v-if="checkType">
              <div class="is-flex js-between">
                <p class="font-16 color-70">Type</p>
                <p class="font-16 color-35 font-weight-500">
                  {{ profile.type }}
                </p>
              </div>
              <el-divider></el-divider>
            </div> -->

            <!-- <div class="is-flex js-between">
              <p class="font-16 color-70">Learning hour</p>
              <p class="font-16 color-35 font-weight-500">{{ profile.hour }}</p>
            </div>
            <el-divider></el-divider> -->
          </div>
        </el-form>
        <el-row>
          <el-col :span="24">
            <div class="text-center">
              <el-button v-if="editdata" @click="saveProfile" type="primary">
                Save
              </el-button>
            </div>
          </el-col>
        </el-row>
      </div>
    </defaultTemplate>
  </div>
</template>

<script>
import { HTTP, HTTPDtm } from "@/service/axios";
import defaultTemplate from "@/template/default.vue";
export default {
  computed: {
    user() {
      return this.$store.state.user;
    },
    checkType() {
      let check = this.profile.curriculum.findIndex(a => a == "DTA Pre-Master");
      return check > -1 ? false : true;
    }
  },
  mounted() {
    this.getProfile();
  },
  components: {
    defaultTemplate
  },
  data() {
    return {
      whProfile: 100,
      objName: ["firstName", "lastName", "profileImage", "profileImageId"],
      editdata: false,
      getProfiles: null,
      rules: {
        firstName: [
          {
            required: true,
            message: "Please input First name",
            trigger: "blur"
          }
        ],
        lastName: [
          {
            required: true,
            message: "Please input Last name",
            trigger: "blur"
          }
        ],
        curriculum: [
          {
            required: true,
            message: "Please select Curriculum",
            trigger: "blur"
          }
        ],
        type: [
          {
            required: true,
            message: "Please input Type",
            trigger: "blur"
          }
        ]
      },
      ruleForm: {
        firstName: "",
        lastName: ""
      },
      renderProfile: false,
      profile: {
        name: "Lalisa Blackpink",
        profileImage:
          "https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png",
        curriculum: ["DTM"],
        type: "Transformer Manager (TM)",
        // semester: "1/2020",
        hour: "450 hrs."
      }
    };
  },
  methods: {
    uploadProfile(file) {
      this.renderProfile = false;
      HTTPDtm.defaults.headers.common.Authorization =
        `Bearer ` + this.user.accessToken;
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-CSRF-TOKEN": this.$csrfToken
        }
      };
      let formData = new FormData();
      formData.append("files", file.raw);
      HTTPDtm.post(`/file/upload`, formData, config).then(res => {
        console.log(res);
        this.ruleForm.profileImage = res.data.path;
        this.ruleForm.profileImageId = res.data.id;
        this.objName.map(item => {
          let obj = {
            label: item,
            value: res.data[item]
          };
          this.$store.commit("SET_USER_OBJ", obj);
        });
        this.updateProfile(false);
      });
    },
    saveProfile(checkvalid = true) {
      if (checkvalid) {
        this.$refs["ruleForm"].validate(valid => {
          if (valid && checkvalid) {
            this.updateProfile();
          }
        });
      } else {
        this.updateProfile(false);
      }
    },
    updateProfile(edit = true) {
      HTTPDtm.defaults.headers.common.Authorization =
        `Bearer ` + this.user.accessToken;
      HTTPDtm.post(`/update/profile`, this.ruleForm)
        .then(res => {
          this.getProfile();
        })
        .catch(e => {
          console.log("Update profile Error", e);
        })
        .finally(() => {
          if (edit) {
            this.editdata = false;
          }
        });
    },
    getProfile() {
      /* from learner profile */
      // HTTP.defaults.headers.common.Authorization =
      //   `Bearer ` + this.user.accessToken;
      // HTTP.get(`profile`)
      //   .then((res) => {
      //     if (res.data.success) {
      //       this.getProfile = res.data.obj;
      //     }
      //   })
      //   .catch((e) => {
      //     console.log(e);
      //     this.alertCatchError(e.response.status);
      //   });

      /* from DTM API */
      HTTPDtm.defaults.headers.common.Authorization =
        `Bearer ` + this.user.accessToken;
      HTTPDtm.get(`profile`)
        .then(res => {
          this.getProfiles = res.data;
          //this.profile.curriculum = ["DTA Reskill"];
          this.profile.curriculum = res.data.kyc;
          this.profile.package = res.data.package;
          this.ruleForm = res.data;
          this.objName.map(item => {
            let obj = {
              label: item,
              value: res.data[item]
            };
            this.$store.commit("SET_USER_OBJ", obj);
          });
        })
        .catch(e => {
          console.log(e);
          this.alertCatchError(e.response.status);
        })
        .finally(() => {
          setTimeout(() => {
            this.renderProfile = true;
          }, 600);
        });
    }
  }
};
</script>
